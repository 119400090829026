import { redirect } from "react-router-dom";

export const projectManagementRoutes = {
  path: "project-management",
  async lazy() {
    let { ProjectManagement } = await import(
      "@/pages/ProjectManagement/ProjectManagement"
    );
    return { Component: ProjectManagement };
  },
  children: [
    {
      path: "",
      loader: () => redirect("/ui/project-management/business-enquiry"),
    },
    {
      path: "business-enquiry",
      async lazy() {
        let { BusinessEnquiry } = await import(
          "@/pages/ProjectManagement/BusinessEnquiry/BusinessEnquiry"
        );
        return { Component: BusinessEnquiry };
      },
    },
    {
      path: "contract-registration",
      async lazy() {
        let { ContractRegistration } = await import(
          "@/pages/ProjectManagement/Contracts/ContractRegistration"
        );
        return { Component: ContractRegistration };
      },
    },
    {
      path: "contract-registration-form",
      async lazy() {
        let { ContractRegistrationForm } = await import(
          "@/pages/ProjectManagement/Contracts/ContractRegistrationForm"
        );
        return { Component: ContractRegistrationForm };
      },
    },
    {
      path: "task-order-form",
      async lazy() {
        let { TaskOrderForm } = await import(
          "@/pages/ProjectManagement/Contracts/TaskOrderForm"
        );
        return { Component: TaskOrderForm };
      },
    },
    {
      path: "invoice-rate-form",
      async lazy() {
        let { InvoiceRateForm } = await import(
          "@/pages/ProjectManagement/Contracts/InvoiceRateForm"
        );
        return { Component: InvoiceRateForm };
      },
    },
    {
      path: "contract-poc-form",
      async lazy() {
        let { ContractPocForm } = await import(
          "@/pages/ProjectManagement/Contracts/ContractPocForm"
        );
        return { Component: ContractPocForm };
      },
    },
    {
      path: "project-execution",
      async lazy() {
        let { ProjectExecution } = await import(
          "@/pages/ProjectManagement/ProjectExecution"
        );
        return { Component: ProjectExecution };
      },
    },
    {
      path: "business-enquiry-form",
      async lazy() {
        let { BusinessEnquiryForm } = await import(
          "@/pages/ProjectManagement/BusinessEnquiry/BusinessEnquiryForm"
        );
        return { Component: BusinessEnquiryForm };
      },
    },
  ],
};
