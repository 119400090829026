import { redirect } from "react-router-dom";

export const employeeManagementRoutes = {
  path: "employee",
  async lazy() {
    let { EmployeeManagement } = await import(
      "@/pages/Employee/EmployeeManagement"
    );
    return { Component: EmployeeManagement };
  },
  children: [
    {
      path: "",
      loader: () => redirect("/ui/employee/employee-listing"),
    },
    {
      path: "employee-listing",
      async lazy() {
        let { EmployeeListing } = await import(
          "@/pages/Employee/EmployeeListing"
        );
        return { Component: EmployeeListing };
      },
    },
    {
      path: "add-employee",
      async lazy() {
        let { AddEmployeeForm } = await import("@/pages/Employee/AddEmployee");
        return { Component: AddEmployeeForm };
      },
    },
  ],
};
