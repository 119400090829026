import { getCookie } from "@/lib/cookies.util";
import { apiConfig } from "./api.config";
import store, { loaderActions } from "@/store/store";
import { toast } from "sonner";
import { AUTH_TOKEN, COMMON_MESSAGES } from "@/lib/constants";

apiConfig.interceptors.request.use(
  (config) => {
    //enable common api loader
    store.dispatch(loaderActions.enableLoader());

    // add token if present
    const token = getCookie(AUTH_TOKEN);
    if (token != null && token != undefined) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiConfig.interceptors.response.use(
  async (response) => {
    // SLOW API RESPONSE FOR DEMO ONLY
    await new Promise((resolve) => setTimeout(resolve, 2000));

    // disable common api loader
    store.dispatch(loaderActions.disableLoader());
    return response;
  },
  (error) => {
    //disable common api loader
    store.dispatch(loaderActions.disableLoader());

    // show error message received from the server
    // OR generic message if no message provided
    if (error?.response?.data?.message) {
      toast.error(error.response?.data?.message);
    } else {
      toast.error(COMMON_MESSAGES.INTERNAL_SERVER_ERROR);
    }

    if (error.response && error.response.status === 401) {
      // call the refresh token api here
      // Handle 401 error, e.g., redirect to login or refresh token
    }
    return Promise.reject(error);
  }
);

export { apiConfig as apiClient };
