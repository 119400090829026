export const ROUTES = {
  LOGIN: {
    RELATIVE: "",
    ABSOLUTE: "/",
  },
  UI: {
    RELATIVE: "ui",
    ABSOLUTE: "/ui",
    DASHBOARD: {
      RELATIVE: "dashboard",
      ABSOLUTE: "/ui/dashboard",
    },
    HUMAN_RESOURCES: {
      RELATIVE: "human-resources",
      ABSOLUTE: "/ui/human-resources",
      SELF_PROFILE: {
        RELATIVE: "self-profile",
        ABSOLUTE: "/ui/human-resources/self-profile",
      },
    },
    PROJECT_MANAGEMENT: {
      BUSINESS_ENQUIRY: {
        RELATIVE: "business-enquiry",
        ABSOLUTE: "/ui/project-management/business-enquiry",
        BUSINESS_ENQUIRY_FORM: {
          RELATIVE: "business-enquiry-form",
          ABSOLUTE: "/ui/project-management/business-enquiry-form",
        },
      },
      CONTRACT_REGISTRATION: {
        RELATIVE: "contract-registration",
        ABSOLUTE: "/ui/project-management/contract-registration",
        CONTRACT_REGISTRATION_FORM: {
          RELATIVE: "contract-registration-form",
          ABSOLUTE: "/ui/project-management/contract-registration-form",
        },
      },
      PROJECT_EXECUTION: {
        RELATIVE: "project-execution",
        ABSOLUTE: "/ui/project-management/project-execution",
        PROJECT_EXECUTION_FORM: {
          RELATIVE: "project-execution-form",
          ABSOLUTE: "/ui/project-management/project-execution-form",
        },
      },
    },
  },
};
