export const API_V1 = "/api";

export const ALL_COUNTRIES =
  "https://countriesnow.space/api/v0.1/countries/iso";
export const STATES_BY_COUNTRY =
  "https://countriesnow.space/api/v0.1/countries/states";

export const SIGN_OUT = API_V1 + "/sign-out";
export const AUTHENTICATE = API_V1 + "/authenticate";
export const BUSINESS_ENQUIRY = API_V1 + "/businessEnquiry";

/// Employee Management
export const ESTIMATION_METHODS = API_V1 + "/estimation-methods";
export const CONTRACT_COMPANIES = API_V1 + "/contractCompanies";
export const CONTRACTS = API_V1 + "/contracts";

//Home Screen
export const FEEDS = API_V1 + "/feeds";
export const UPCOMING_HOLIDAY = API_V1 + "/holidays";

//event requests
export const MERGED_EVENT_REQUESTS = API_V1 + "/merge-event-requests";
