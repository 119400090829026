import * as React from "react";
import { cn } from "@/lib/utils";
import { CircleX } from "lucide-react";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: React.ReactElement;
  onClearInputField?: () => void;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, icon, onClearInputField, ...props }, ref) => {
    return (
      <div className="relative flex items-center">
        {icon && (
          <span className="absolute left-3 flex items-center pointer-events-none">
            {icon}
          </span>
        )}
        <input
          type={type}
          className={cn(
            "flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
            icon ? "pl-10" : "", // Adjust padding if there's an icon
            onClearInputField && "pr-10",
            className
          )}
          ref={ref}
          {...props}
        />
        {onClearInputField && (
          <CircleX
            className="absolute right-3 text-slate-600 cursor-pointer"
            onClick={onClearInputField}
            size={22}
          />
        )}
      </div>
    );
  }
);

Input.displayName = "Input";

export { Input };
