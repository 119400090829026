import Login from "@/pages/Login";
import { createBrowserRouter } from "react-router-dom";
import { authCheckLoader, requireAuthLoader } from "@/auth/authProvider";
import { projectManagementRoutes } from "./projectManagement.routes";
import { employeeManagementRoutes } from "./employeeManagement.routes";
import { humanResourcesRoutes } from "./humanResources.routes";
import { userRegistrationManagementRoutes } from "./userRegistration.routes";
import NotFoundError from "@/pages/Error/NotFoundError";
import { selfServiceRoutes } from "./selfService.routes";

const routes = [
  {
    path: "/",
    element: <Login />,
    loader: authCheckLoader,
    errorElement: <NotFoundError />,
  },
  {
    path: "/ui",
    async lazy() {
      let { Root } = await import("@/pages/Root");
      return { Component: Root };
    },
    errorElement: <NotFoundError />,
    children: [
      {
        path: "dashboard",
        async lazy() {
          let { Dashboard } = await import("@/pages/Dashboard");
          return { Component: Dashboard };
        },
      },
      projectManagementRoutes,
      employeeManagementRoutes,
      humanResourcesRoutes,
      userRegistrationManagementRoutes,
      selfServiceRoutes,
    ],
  },
];

function attachLoaderRecursively(routes: any, loader: any) {
  for (const route of routes) {
    // if a loader is not attached with a route
    // then requireAuthLoader will be attached to the route
    if (route.path && route.loader === undefined) {
      route.loader = loader;
    }
    if (route.children?.length) {
      attachLoaderRecursively(route.children, loader);
    }
  }
  return routes;
}

const router = createBrowserRouter(
  attachLoaderRecursively(routes, requireAuthLoader)
);

export default router;
