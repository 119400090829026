import { redirect } from "react-router-dom";

export const userRegistrationManagementRoutes = {
  path: "onboarding",
  async lazy() {
    let { UserRegistrationManagement } = await import(
      "@/pages/UserRegistration/UserRegistrationManagement"
    );
    return { Component: UserRegistrationManagement };
  },
  children: [
    {
      path: "",
      loader: () => redirect("/ui/onboarding/user-listing"),
    },
    {
      path: "user-listing",
      async lazy() {
        let { UserListing } = await import(
          "@/pages/UserRegistration/UserListing"
        );
        return { Component: UserListing };
      },
    },
    {
      path: "add-user",
      async lazy() {
        let { AddUserForm } = await import(
          "@/pages/UserRegistration/AddUser"
        );
        return { Component: AddUserForm };
      },
    },

  ],
};
