import { AUTH_TOKEN } from "@/lib/constants";
import { getCookie, removeCookie, setCookie } from "@/lib/cookies.util";
import { LoaderFunctionArgs, redirect } from "react-router-dom";

export const authProvider: {
  token: null | string;
  signin(token: string): Promise<void>;
  signout(): Promise<void>;
} = {
  token: null,
  async signin(token: string) {
    setCookie(AUTH_TOKEN, token);
    authProvider.token = token;
  },
  async signout() {
    removeCookie(AUTH_TOKEN);
    authProvider.token = null;
  },
};

export function requireAuthLoader({ request }: LoaderFunctionArgs) {
  // If the user is not logged in and tries to access protected content, we redirect
  // them to `/login` with a `from` parameter that allows login to redirect back
  // to this page upon successful authentication. This allows us to send them
  // along to that page after they login, which is a nicer user experience
  // than dropping them off on the home page.
  if (getCookie(AUTH_TOKEN) == null) {
    const params = new URLSearchParams();
    params.set("redirectTo", new URL(request.url).pathname);
    return redirect("/?" + params.toString());
  }
  return null;
}

export function authCheckLoader() {
  if (getCookie(AUTH_TOKEN) != null) {
    return redirect("/ui/dashboard");
  }
  return null;
}
