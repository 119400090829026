import { redirect } from "react-router-dom";

export const selfServiceRoutes = {
  path: "self-service",
  async lazy() {
    let { SelfServiceManagement } = await import(
      "@/pages/SelfService/SelfServiceManagement"
    );
    return { Component: SelfServiceManagement };
  },
  children: [
    {
      path: "",
      loader: () => redirect("/ui/self-service/event-requests"),
    },
    {
      path: "event-requests",
      async lazy() {
        let { EventRequestListing } = await import(
          "@/pages/SelfService/EventRequestListing"
        );
        return { Component: EventRequestListing };
      },
    },
    {
      path: "employee-leave-form",
      async lazy() {
        let { EmployeeLeaveForm } = await import(
          "@/pages/SelfService/EmployeeLeaveForm"
        );
        return { Component: EmployeeLeaveForm };
      },
    },

    ///employee-work-from-home-form
    {
      path: "employee-work-from-home-form",
      async lazy() {
        let { EmployeeWorkFromHomeForm } = await import(
          "@/pages/SelfService/EmployeeLeaveWorkFromHomeForm"
        );
        return { Component: EmployeeWorkFromHomeForm };
      },
    },

    ///attendance-regularization-form
    {
      path: "employee-attendance-regularization-form",
      async lazy() {
        let { EmployeeAttendanceRegularizationForm } = await import(
          "@/pages/SelfService/EmployeeAttendanceRegularizationForm"
        );
        return { Component: EmployeeAttendanceRegularizationForm };
      },
    },
  ],
};
