import { createSlice, configureStore } from "@reduxjs/toolkit";

const initialAppState = { loader: false };

const loaderSlice = createSlice({
  name: "loaderSlice",
  initialState: initialAppState,
  reducers: {
    // (state, action: PayloadAction<>)
    enableLoader(state) {
      state.loader = true;
    },
    disableLoader(state) {
      state.loader = false;
    },
  },
});

const store = configureStore({
  reducer: {
    loaderSliceReducer: loaderSlice.reducer,
  },
});

export const loaderActions = loaderSlice.actions;

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type from configureStore object
export type AppDispatch = typeof store.dispatch;
