import { RouterProvider } from "react-router-dom";
import router from "./router/index.tsx";
import { ThemeProvider } from "@/components/layout/ThemeToggle/theme-provider.tsx";
import { Toaster } from "./components/ui/sonner.tsx";
import { useAppSelector } from "./store/hooks.ts";
import { Skeleton } from "./components/ui/skeleton.tsx";

// import { localesKeys } from "./locales/localesKeys";

// type ApiResponse = Partial<Record<(typeof localesKeys)[number], string>>;

function App() {
  const loader = useAppSelector((state) => state.loaderSliceReducer.loader);

  // useEffect(() => {
  //   const loadConstants = async () => {
  //     const master_labels: ApiResponse = await fetch("someapi").then(
  //       (response) => response.json()
  //     );
  //     console.log(master_labels.limit);
  //   };

  //   loadConstants();
  // }, []);

  return (
    <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
      <Toaster
        richColors
        expand={true}
        position="top-right"
        toastOptions={{}}
        theme="light"
        closeButton
      />
      {loader && (
        <Skeleton className="fixed top-0 left-0 z-[1000] w-full h-[10px] rounded-none bg-blue-500" />
      )}
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
