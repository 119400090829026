import { redirect } from "react-router-dom";

export const humanResourcesRoutes = {
  path: "human-resources",
  async lazy() {
    let { HumanResources } = await import(
      "@/pages/HumanResources/HumanResources"
    );
    return { Component: HumanResources };
  },
  children: [
    {
      path: "",
      loader: () => redirect("/ui/human-resources/dashboard"),
    },
    {
      path: "dashboard",
      async lazy() {
        let { HumanResourcesDashboard } = await import(
          "@/pages/HumanResources/HumanResourcesDashboard"
        );
        return { Component: HumanResourcesDashboard };
      },
    },
    {
      path: "self-profile",
      async lazy() {
        let { SelfProfile } = await import(
          "@/pages/HumanResources/SelfProfile"
        );
        return { Component: SelfProfile };
      },
    },
    {
      path: "benefits-and-compensation",
      async lazy() {
        let { BenefitsAndCompensation } = await import(
          "@/pages/HumanResources/BenefitsAndCompensation"
        );
        return { Component: BenefitsAndCompensation };
      },
    },
    {
      path: "asset-list",
      async lazy() {
        let { AssetList } = await import("@/pages/HumanResources/AssetList");
        return { Component: AssetList };
      },
    },
    {
      path: "documents",
      async lazy() {
        let { Documents } = await import("@/pages/HumanResources/Documents");
        return { Component: Documents };
      },
    },
  ],
};
