import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function jsonParse(input: string) {
  if (input === null && input === undefined) return null;

  try {
    return JSON.parse(input);
  } catch (e) {
    console.error("Unable to parse json - ", e);
    return null;
  }
}
